import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'
import Header from '../components/header/header'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageDetailedHeader from '../components/shared/pageDetailedHeader'

const NotFoundPageContainerWrapper = styled.div`
  padding: 60px 20px 150px 20px;
`
const NotFoundPageContainerInner = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  a {
    background: #f5d123;
    padding: 4px 8px;
    border-radius: 3px;
  }
`

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    {
      headerBG: file(relativePath: { eq: "page-header-about.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Header colour="#F5D124" />
      <PageDetailedHeader
        bg={data.headerBG}
        intro="NOT FOUND - You just hit a page that doesn&#39;t exist..."
      />
      <NotFoundPageContainerWrapper>
        <NotFoundPageContainerInner>
          <p>
            Please go back to my <Link to="/">homepage</Link> or use the
            navigation to find your way.
          </p>
        </NotFoundPageContainerInner>
      </NotFoundPageContainerWrapper>
    </Layout>
  )
}

export default NotFoundPage
